import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import M from "@materializecss/materialize/dist/js/materialize.min";
import "select2/dist/js/select2.min";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:load", this.load.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.load.bind(this));
  }

  initialize() {
    this.initializeComponents();
  }

  load() {
    this.initializeComponents();
  }

  initializeComponents() {
    var selectElm = document.querySelectorAll("select");
    M.FormSelect.init(selectElm);

    $(".page-per-select select").on("change", this.changePerPage.bind(this));

    this.initializeDatePicker();
    this.initializeSelect2();
    this.initializeAutoSubmitSearch();

    $(".select-dropdown").on("mousedown", (e) => {
      $(e.target).parent().click();
      e.preventDefault();
    });
  }

  changePerPage() {
    let queryParams;
    if ($.isEmptyObject(location.search)) {
      queryParams = "?per=" + $("#per").val();
    } else {
      queryParams = "?per=" + $("#per").val() + "&" + location.search.substr(1);
    }

    location.search = queryParams;
  }

  initializeDatePicker() {
    const elems = document.querySelectorAll(".date-picker");
    const options = {
      format: "yyyy-mm-dd",
      yearRange: 7,
      i18n: {
        months: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        monthsShort: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        weekdays: [
          "日曜日",
          "月曜日",
          "火曜日",
          "水曜日",
          "木曜日",
          "金曜日",
          "土曜日",
        ],
        weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
        weekdaysAbbrev: ["日", "月", "火", "水", "木", "金", "土"],
        clear: "クリア",
        cancel: "閉じる",
      },
    };
    M.Datepicker.init(elems, options);
  }

  initializeSelect2() {
    $(".select2").select2();
  }

  initializeAutoSubmitSearch() {
    $(".auto-submit-search-form input[type=checkbox]").on(
      "change",
      function () {
        $(this).closest("form").submit();
      }
    );

    $(".auto-submit-search-form input[type=radio]").on("change", function () {
      $("#input_words").val(null);
      $(this).closest("form").submit();
    });

    $(".auto-submit-search-form select").on("change", function () {
      $(this).closest("form").submit();
    });
  }
}
