import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["companyName"];

  connect() {
    document.addEventListener("turbo:load", this.load.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.load.bind(this));
  }

  initialize() {
    this.initializeSelect2();
  }

  load() {
    this.initializeSelect2();
  }

  /**
   * すでに登録済みの会社名かどうかをチェックする
   */
  checkUniqueCompanyName() {
    const warnMsg = document.getElementById("company-name-warning");
    warnMsg.classList.add("hide");

    // debounce
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const companyName = this.companyNameTarget.value;

      fetch(`/clients/check_company_names?company_name=${companyName}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.exists) warnMsg.classList.remove("hide");
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);
  }

  initializeSelect2() {
    $(".select2").select2({
      templateResult: function (data) {
        const option = $(data.element);
        const result = $("<span></span>");
        result.text(data.text);

        if (option.data("antisocial-check") === "ng") {
          result.append("<span style='color: red;'> : 反社チェックNG</span>");
          result.addClass("antisocial-check-ng");
        }

        return result;
      },
    });
  }
}
